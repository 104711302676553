var SUPERSTRONG = (!SUPERSTRONG ? $({}) : SUPERSTRONG);
(function ($){

	SUPERSTRONG.ImageFitter = {};
	SUPERSTRONG.ImageFitter.fittAll = function (){

		layoutFn = function (elm){

			console.log ('layedout')
			var parentW = elm.parent()[0].getBoundingClientRect().width,
				parentH = elm.parent()[0].getBoundingClientRect().height,
				transformOffsetW = elm.parent()[0].getBoundingClientRect().width - elm.parent().width(),
				transformOffsetH = elm.parent()[0].getBoundingClientRect().height - elm.parent().height(),
				mediaW = elm[0].naturalWidth,
				mediaH = elm[0].naturalHeight,
				sw = parentW/mediaW,
				sh = parentH/mediaH,
				s = 1,
				offsetW = 0,
				offsetH = 0;
			if (elm.hasClass('fill')){

				s = Math.max (sw,sh);

			}else {

				s = Math.min (sw,sh);

			}

			offsetW = ((parentW-(mediaW*s))-transformOffsetW)/2;
			offsetH = ((parentH-(mediaH*s))-transformOffsetH)/2;
			if (elm.hasClass('fix-top')){

				offsetH = 0;

			}
			elm.css({

				marginLeft:offsetW+'px',
				marginTop:offsetH+'px',
				width:mediaW*s+'px',
				height:mediaH*s+'px',
				display:'block',

			});

			elm.addClass('loaded');
			elm.addClass('cover-item');
		};

		var _$elm;
		$('.fittable').each (function (index,item){
			_$elm = $(item);


			if (_$elm[0].complete){

				layoutFn(_$elm);

			}else {

				_$elm.on('load',function (){

					layoutFn($(this));
					console.log ('loaded')
				});

			}


		});

	};

	$(document).ready(SUPERSTRONG.ImageFitter.fittAll);
	$(window).resize(SUPERSTRONG.ImageFitter.fittAll);

})(jQuery);
